import React from "react"
import classNames from "classnames"
import { DynamicHeightContainer } from "@ep/react-components"
import RoundCaption from "./RoundCaption"
import styles from "../../styles/collapsible.module.styl"
import contentStyles from "../../styles/content.module.styl"

class Collapsible extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
    }

    this.handleCollapse = this.handleCollapse.bind(this)
    this.handleExpand = this.handleExpand.bind(this)
  }

  handleCollapse() {
    this.setState({ collapsed: true })
  }

  handleExpand() {
    this.setState({ collapsed: false })
  }

  render() {
    const [Caption, Content] = this.props.children
    const { collapsed } = this.state
    const containerClass = collapsed
      ? styles.collapsibleContainer
      : styles.collapsibleContainerExpanded
    const toggleClass = collapsed
      ? styles.collapsibleContainerToggle
      : styles.collapsibleContainerToggleExpanded

    return (
      <DynamicHeightContainer>
        <div className={containerClass}>
          <RoundCaption
            className={classNames(
              contentStyles.flexRow,
              contentStyles.alignCenter
            )}
          >
            <div
              className={styles.collapsibleCaption}
              onClick={collapsed ? this.handleExpand : this.handleCollapse}
            >
              <div className={styles.collapsibleCaptionContent}>{Caption}</div>
              <div className={toggleClass}>
                <button className={collapsed ? "collapsed" : "expanded"} />
              </div>
            </div>
          </RoundCaption>
          {this.state.collapsed ? null : (
            <div className={styles.collapsibleContainerContent}>{Content}</div>
          )}
        </div>
      </DynamicHeightContainer>
    )
  }
}

export default Collapsible
