import React from "react"
import { string } from "prop-types"
import contentStyles from "../../styles/content.module.styl"

const SectionTitle = ({ sectionTitle }) => (
  <h2 className={contentStyles.mb20}>{sectionTitle}</h2>
)

SectionTitle.propTypes = {
  sectionTitle: string.isRequired,
}

export default SectionTitle
