import classNames from "classnames"
import Immutable from "immutable"
import React from "react"
import { instanceOf, oneOf } from "prop-types"
import styles from "../../styles/content.module.styl"

// TODO I'm assuming that src of image will be base64, otherwise this component will require some
// improvements
const SectionImage = ({ image, type }) => {
  const position = image.get("position")
  return (
    <div
      className={classNames(
        styles.sectionImage,
        styles[position],
        styles[type]
      )}
    >
      <img src={image.get("src")} alt="section" />
    </div>
  )
}

SectionImage.propTypes = {
  image: instanceOf(Immutable.Map).isRequired,
  type: oneOf(["circle", "normal"]),
}

SectionImage.defaultProps = {
  type: "circle",
}

export default SectionImage
