import React from "react"
import classNames from "classnames"
import { number, string, oneOfType } from "prop-types"
import styles from "../../styles/components.module.styl"

const getStyles = size => ({
  width: `${size}px`,
  lineHeight: `${size}px`,
  height: `${size}px`,
})

const Circle = ({ content, className, size }) => (
  <div className={classNames(styles.circle, className)} style={getStyles(size)}>
    {content}
  </div>
)

Circle.propTypes = {
  className: string,
  content: oneOfType([string, number]).isRequired,
  size: number.isRequired,
}

Circle.defaultProps = {
  className: "",
}

export default Circle
