import React from 'react';
import { connect } from 'react-redux';
import { setOutcomeValue } from '../state/actions/outcomeValue';
import OutcomeValueAssessment from '../components/outcomes/OutcomeValueAssessment';

const mapStateToProps = state => ({
  outcomeValues: state.get('outcomeValues'),
});

const mapDispatchToProps = dispatch => ({
  setOutcomeValue: (outcomeId, value) => dispatch(setOutcomeValue(outcomeId, value)),
});

const OutcomesValueAssessment = ({ outcomes, setOutcomeValue, outcomeValues }) => (
  <div>
    {outcomes.map((outcome, index) => {
      const value = outcomeValues.get(outcome.get('key'));
      return (
        <OutcomeValueAssessment
          key={index}
          outcome={outcome.toJS()}
          onChange={setOutcomeValue}
          value={value}
        />
      );
    })}
  </div>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutcomesValueAssessment);
