import React from "react"
import SectionTitle from "./SectionTitle"
import TextContent from "./TextContent"
import SectionImage from "./SectionImage"
import styles from "../../styles/content.module.styl"

const Section = ({ section }) => {
  return (
    <div className={styles.section}>
      {section.get("image") && <SectionImage image={section.get("image")} />}
      <div>
        <SectionTitle sectionTitle={section.get("title")} />
        <TextContent content={section.get("content")} />
      </div>
    </div>
  )
}

export default Section
