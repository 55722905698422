import classNames from "classnames"
import React from "react"
import styles from "../../styles/components.module.styl"
import { number } from "prop-types"

const captionInlineStyles = height => ({
  minHeight: `${height}px`,
  borderRadius: `${height / 2}px`,
  padding: `0 ${height / 2}px`,
  marginBottom: "6px",
})

const RoundCaption = props => (
  <div
    className={classNames(styles.roundCaption, props.className)}
    style={captionInlineStyles(props.height)}
  >
    {props.children}
  </div>
)

RoundCaption.propTypes = {
  height: number,
}

RoundCaption.defaultProps = {
  height: 56,
}

export default RoundCaption
