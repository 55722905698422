import Immutable from "immutable"
import React from "react"
import _ from "underscore"
import ArrowNavigation from "../components/navigation/ArrowNavigation"
import Collapsible from "../components/common/Collapsible"
import SubPageHeader from "../components/header/SubPageHeader"
import HeaderWithIcon from "../components/common/HeaderWithIcon"
import OutcomesValueAssessment from "../containers/OutcomesValueAssessment"
import Section from "../components/common/Section"
import Layout from "../components/layout"
import TextContent from "../components/common/TextContent"
import styles from "../styles/content.module.styl"
import { getLinkToSubpage } from "../lib/utils"

const Facts = ({ location, data, ...props }) => {
  const nodeData = data.decisionAidsJson
  const markerStates = Immutable.fromJS(nodeData.markerStates)
  const processOutcomesExplanation = Immutable.fromJS(
    nodeData.processOutcomesExplanation
  )
  const keyFacts = Immutable.fromJS(nodeData.keyFacts)
  const { pageId, groupId, subPage, group } = props.pathContext

  const prevLink = `/${pageId}`
  const nextLink = getLinkToSubpage(pageId, groupId, "options")

  return (
    <Layout>
      <SubPageHeader
        title={nodeData.title}
        group={group}
        hasGroups={!_.isEmpty(nodeData.groups)}
        location={location}
        logo={nodeData.logo}
        pageId={pageId}
        groupId={groupId}
        subPage={subPage}
      />
      <div className={styles.content}>
        <HeaderWithIcon type="question" title="What should you know?" />
        {keyFacts.map((section, key) => {
          return <Section key={key} section={section} />
        })}

        <h2 className={styles.sectionTitle}>
          Explanation of outcomes used in process:
        </h2>
        {processOutcomesExplanation.map((outcome, index) => {
          const markerState = markerStates.find(
            markerState => markerState.get("key") === outcome
          )
          return (
            <Collapsible key={index}>
              <h4>{markerState.get("explanationHeader")}</h4>
              <TextContent content={markerState.get("content")} />
            </Collapsible>
          )
        })}
        <HeaderWithIcon type="assessment" title="Assess your values" />
        <TextContent content='To calculate an individual recommendation and to help understand how good or bad a health state is we have created the scales below with "dead" marked as 0 and "good health" marked as 100. We would like you to indicate on these scales how good or bad each health state is in your opinion:' />
        <OutcomesValueAssessment
          outcomes={markerStates.filter(ms => ms.get("marker"))}
        />
        <ArrowNavigation prevLink={prevLink} nextLink={nextLink} />
      </div>
    </Layout>
  )
}

export default Facts

export const query = graphql`
  query FactsQuery($pageId: String) {
    decisionAidsJson(pageId: { eq: $pageId }) {
      title
      logo
      keyFacts {
        title
        content
        image {
          src
          position
        }
      }
      processOutcomesExplanation
      markerStates {
        key
        marker
        markerPrefix
        content
        summaryTitle
        explanationHeader
        forIntervention
      }
    }
  }
`
